import React, { Fragment } from "react";
// import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  Button,
} from "@material-ui/core";

import VenditeAnnualiChart from "./VenditeAnnualiChart";
import VenditeAnnualiPerTipologiaChart from "./VenditeAnnualiPerTipologiaChart";
import VenditeAnnualiTopTenChart from "./VenditeAnnualiTopTenChart";
import Box from "@material-ui/core/Box";

import TotaleCantina from "../TotaleCantina";

export default function Dashboard() {
  const anno = new Date().getFullYear();
  const qrcode = localStorage.getItem("qrcode");

  const qrcodeurl =
    "https://carta-vini.azurewebsites.net/data/qrcode/" + qrcode;
  let cartaurl = qrcode !== '072B030BA126B2F4B2374F342BE9ED44' ?  "https://qr.carta-dei-vini.it/index.html?qrcode=" + qrcode : "https://abraxas-qr.carta-dei-vini.it/index.html?qrcode=" + qrcode;
  return (
    <Fragment>
      <Grid container spacing={2} style={{ marginTop: 20 }}>
        <Grid item md={9}>
          <div
            style={{
              borderRadius: 3,

              boxShadow:
                "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
            }}
          >
            <CardContent>
              <Typography gutterBottom variant="h4" component="div">
                Ciao!
              </Typography>
              <Typography variant="body1" color="initial">
                Sulla destra puoi inquadrare con il tuo smartphone il codice QR
                per visualizzare la carta dei vini. Per dubbi o domande puoi
                sempre contattarci scrivendo a{" "}
                <a href="mailto:info@carta-dei-vini.it">
                  info@carta-dei-vini.it
                </a>
              </Typography>
              <Typography variant="span" color="initial">
                <p></p>
              </Typography>
            </CardContent>
            <CardActions>
              <div
                style={{
                  padding: 10,
                  borderRadius: 3,
                  backgroundColor: "#d5d5d5",
                  boxShadow:
                    "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                }}
              >
                <a
                  href="https://carta-dei-vini.it/MANUALE_CARTA_DEI_VINI.pdf"
                  target="_blank"
                  style={{
                    fontSize: 14,
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  MANUALE
                </a>
              </div>
              <div
                style={{
                  padding: 10,
                  borderRadius: 3,
                  backgroundColor: "#d5d5d5",
                  boxShadow:
                    "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                }}
              >
                <a
                  href={cartaurl}
                  target="_blank"
                  style={{
                    fontSize: 14,
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  VEDI CARTA DEI VINI
                </a>
              </div>
              <div
                style={{
                  padding: 10,
                  borderRadius: 3,
                  backgroundColor: "#d5d5d5",
                  boxShadow:
                    "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                }}
              >
                <a
                  href={qrcodeurl}
                  target="_blank"
                  style={{
                    fontSize: 14,
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  SCARICA CODICE QR
                </a>
              </div>

              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.reindal.carta_dei_vini&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              >
                <img
                  height={60}
                  alt="Disponibile su Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/it_badge_web_generic.png"
                />
              </a>
            </CardActions>
          </div>
        </Grid>
        <Grid item md={3}>
          <div
            style={{
              padding: 3,
              borderRadius: 3,
              boxShadow:
                "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
            }}
          >
            {qrcode ? (
              <CardMedia component="img" alt="Codice QR" image={qrcodeurl} />
            ) : (
              <p>Per visualizzare il QR code rifare l'accesso.</p>
            )}
          </div>
        </Grid>
      </Grid>
      <Box component="div" m={1}>
        <TotaleCantina />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <VenditeAnnualiTopTenChart anno={anno} />
        </Grid>
         <Grid item xs={12} sm={12} md={6}>
          <VenditeAnnualiPerTipologiaChart anno={anno} />
        </Grid>
       <Grid item xs={12} sm={12}>
          <VenditeAnnualiChart anno={anno} />
        </Grid>
      </Grid>
    </Fragment>
  );
}
